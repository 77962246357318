import { useState } from 'react';

const Navbar = ({ children }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const pathname = window.location.pathname;

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    }

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    return (
        <>
            <nav className="p-4 bg-indigo-950 print:hidden">
                <div className="container mx-auto flex justify-between items-center">
                    <div className="md:hidden flex items-center">
                        <button className="text-white focus:outline-none mr-4" onClick={toggleMenu}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                            </svg>
                        </button>
                        <div>
                            <a href="/" className="text-white text-xl font-bold">
                                Resume Builder
                            </a>
                        </div>
                    </div>
                    <div className="hidden md:flex items-center">
                        <div>
                            <a href="/" className="text-white text-xl font-bold">
                                Resume Builder
                            </a>
                        </div>
                    </div>
                    <div className="hidden md:block">
                        <ul className="flex space-x-4 items-center">
                            <li>
                                <div className="flex items-center justify-center">
                                    <a href="/" className={`text-white ${pathname === '/' ? 'font-bold' : ''}`}>
                                        Home
                                    </a>
                                </div>
                            </li>
                            <li>
                                <div className="flex items-center justify-center">
                                    <a href="/build" className={`text-white ${pathname === '/build' ? 'font-bold' : ''}`}>
                                        Create Resume
                                    </a>
                                </div>
                            </li>
                            {/* <li>
                                <button className="bg-white border border-gray-500 rounded-md py-2 px-4 shadow-sm flex items-center space-x-2 cursor-pointer hover:bg-gray-100 transition-colors duration-300 ease-in-out">
                                    <img src="/google.png" alt="Google Logo" className="w-6 h-6" />
                                    <span className="text-gray-700 font-bold">Sign in with Google</span>
                                </button>
                            </li>
                            <li>
                                <div className="flex items-center justify-center">
                                    <button className='text-white'>
                                        Logout
                                    </button>
                                </div>
                            </li> */}
                        </ul>
                    </div>
                </div>
                {
                    isMenuOpen && (
                        <div className="md:hidden">
                            <div className="fixed top-0 left-0 bottom-0 right-0 z-50 bg-black bg-opacity-50" onClick={closeMenu}>
                                <div className="fixed top-0 bottom-0 left-0 z-50 w-72 py-4 px-6 bg-indigo-950" >
                                    <ul className="space-y-2">
                                        <li>
                                            <div className="flex items-center">
                                                <a href="/" className={`text-white ${pathname === '/' ? 'font-bold' : ''}`}>
                                                    Home
                                                </a>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="flex items-center">
                                                <a href="/build" className={`text-white ${pathname === '/build' ? 'font-bold' : ''}`}>
                                                    Create Resume
                                                </a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )
                }
            </nav >
            {
                children
            }
        </>
    );
};

export default Navbar;