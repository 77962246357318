import { useFormik } from 'formik'
import React, { useState } from 'react'

const TextField = ({ formik, name, label, placeholder, value = '', autoFocus = false }) => {
    return <>
        <div className="mb-4 w-full">
            <label htmlFor={name} className="block text-gray-700 text-sm font-bold mb-2">{label}</label>
            <input type="text" id={name} name={name} value={value || formik.values[name]} onChange={formik.handleChange} className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" placeholder={placeholder} autoFocus={autoFocus} />
        </div>
    </>
}

const TextArea = ({ formik, name, label, placeholder, value = '', autoFocus = false }) => {
    return <>
        <div className="mb-4">
            <label htmlFor={name} className="block text-gray-700 text-sm font-bold mb-2">{label}</label>
            <textarea
                id={name}
                name={name}
                value={value || formik.values[name]}
                onChange={formik.handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                placeholder={placeholder}
                rows="4"
                autoFocus={autoFocus}
            />
        </div>
    </>
}

const ListField = ({ formik, name, label, fields = [], placeholder = '' }) => {
    const [modal, setModal] = useState(0)
    const [formIndex, setFormIndex] = useState(-1)

    // const handleSave = () => {
    //     console.log(modal)
    // }

    return (
        <>
            <div className="mb-4">
                <label onClick={() => {
                    if (fields.length == 0) {
                        formik.setFieldValue(name, [...formik.values[name], ' '])
                    } else {

                        formik.setFieldValue(name, [...formik.values[name], fields.reduce((acc, curr) => { acc[curr.name] = ''; return acc }, {})])
                        setFormIndex(formik.values[name].length)
                        setModal(1)
                    }
                }} className="block text-gray-700 text-sm font-bold mb-2 flex justify-between items-center">
                    <span>{label}</span>
                    <button className="text-gray-600 hover:text-gray-800" type='button'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    </button>
                </label>
                <ul className='list-[square] mx-8'>
                    {
                        formik.values[name].map((item, index) => (
                            fields.length == 0 ? <li key={index} className='mt-2'>
                                <span className='flex justify-between items-center'>
                                    {
                                        fields.length == 0 ? <TextField key={index} formik={formik} name={`${name}[${index}]`} value={formik.values[name][index]} placeholder={placeholder} /> : <span>{label}</span>
                                    }
                                    <div>
                                        {
                                            fields.length != 0 && <button className="p-2 text-gray-600 hover:text-gray-800" type='button' onClick={() => {
                                                setFormIndex(index)
                                                setModal(2)
                                            }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                                                </svg>
                                            </button>
                                        }
                                        <button className="p-2 text-gray-600 hover:text-gray-800" type='button' onClick={() => {
                                            formik.setFieldValue(name, formik.values[name].filter((_, ind) => ind !== index))
                                        }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                            </svg>
                                        </button>
                                    </div>
                                </span>
                            </li>
                                :
                                <li key={index} className='mt-2'>
                                    <b className='flex justify-between items-center'>
                                        <span>{item[fields[0].name]}</span>
                                        <div>
                                            <button className="p-2 text-gray-600 hover:text-gray-800" type='button' onClick={() => {
                                                setFormIndex(index)
                                                setModal(2)
                                            }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                                                </svg>
                                            </button>
                                            <button className="p-2 text-gray-600 hover:text-gray-800" type='button' onClick={() => {
                                                formik.setFieldValue(name, formik.values[name].filter((_, ind) => ind !== index))
                                            }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                </svg>
                                            </button>
                                        </div>
                                    </b>
                                    <ul className='list-[circle] ml-8'>
                                        {
                                            fields.slice(1).map((field, index) => (
                                                <li key={index}>{item[field.name]}</li>
                                            ))
                                        }
                                    </ul>
                                </li>
                        ))
                    }
                </ul>
            </div>
            {
                modal != 0 && <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="fixed inset-0 bg-black opacity-50"></div>
                    <div className="relative bg-white rounded-lg p-8 w-1/2">
                        <button className="absolute top-0 right-0 p-2 text-gray-600 hover:text-gray-800" onClick={() => { setModal(false) }}>
                            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                            </svg>
                        </button>
                        <div className="text-lg font-bold mb-4">{label}</div>
                        <div className="mb-8 w-1/2 m-auto">
                            {
                                fields.map((field, index) => (
                                    field.type == 'text-area' ? <TextArea key={index} formik={formik} name={`${name}[${formIndex}].${field.name}`} label={field.label} value={formik.values[name][formIndex][field.name]} placeholder={field.placeholder} autoFocus={index == 0} />
                                        :
                                        <TextField key={index} formik={formik} name={`${name}[${formIndex}].${field.name}`} label={field.label} value={formik.values[name][formIndex][field.name]} placeholder={field.placeholder} autoFocus={index == 0} />
                                ))
                            }
                        </div>
                        <div className="flex justify-end">
                            <button className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-semibold py-2 px-4 rounded mr-2" type='button' onClick={() => { setModal(false) }}>Close</button>
                            {/* <button className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded" type='button' onClick={handleSave}>Save</button> */}
                        </div>
                    </div>
                </div>
            }
        </>
    )
}


const Resume = () => {
    const formik = useFormik({
        initialValues: {
            name: '',
            phone: '',
            email: '',
            linkedIn: '',
            github: '',
            summary: '',
            education: [
            ],
            skills: [
            ],
            experience: [
            ],
            projects: [
            ],
            achievements: [
            ]
        }
    })

    return (
        <>
            <div className='flex'>
                <div className='w-1/2 print:hidden h-screen overflow-auto'>
                    <form onSubmit={formik.handleSubmit} className='w-3/5 m-auto mt-10'>
                        <TextField formik={formik} name={'name'} label={'Name'} placeholder={'Enter your full name'} autoFocus />
                        <TextField formik={formik} name={'phone'} label={'Phone'} placeholder={'Enter phone number'} />
                        <TextField formik={formik} name={'email'} label={'Email'} placeholder={'Enter email address'} />
                        <TextField formik={formik} name={'linkedIn'} label={'LinkedIn'} placeholder={'Enter linkedIn profile'} />
                        <TextField formik={formik} name={'github'} label={'Github'} placeholder={'Enter github profile'} />
                        <TextArea formik={formik} name={'summary'} label={'Professional Summary'} placeholder={'Enter professional summary'} />
                        <ListField
                            formik={formik}
                            name={'education'}
                            label={'Education'}
                            fields={[
                                {
                                    name: 'degree',
                                    label: 'Degree',
                                    placeholder: 'Enter degree'
                                },
                                {
                                    name: 'organization',
                                    label: 'Organization',
                                    placeholder: 'Enter organization'
                                },
                                {
                                    name: 'duration',
                                    label: 'Duration',
                                    placeholder: 'Enter duration'
                                },
                                {
                                    name: 'result',
                                    label: 'Result',
                                    placeholder: 'Enter result'
                                },
                            ]}
                        />
                        <ListField
                            formik={formik}
                            name={'skills'}
                            label={'Technical Skill'}
                            placeholder={'Enter skill'}
                        />
                        <ListField
                            formik={formik}
                            name={'experience'}
                            label={'Experience'}
                            fields={[
                                {
                                    name: 'organization',
                                    label: 'Organization',
                                    placeholder: 'Enter organization'
                                },
                                {
                                    name: 'role',
                                    label: 'Role',
                                    placeholder: 'Enter role'
                                },
                                {
                                    name: 'duration',
                                    label: 'Duration',
                                    placeholder: 'Enter duration'
                                },
                                {
                                    type: 'text-area',
                                    name: 'details',
                                    label: 'Details',
                                    placeholder: 'Enter more details',
                                },
                            ]}
                        />
                        <ListField
                            formik={formik}
                            name={'projects'}
                            label={'Projects'}
                            fields={[
                                {
                                    name: 'title',
                                    label: 'Title',
                                    placeholder: 'Enter title of project'
                                },
                                {
                                    name: 'description',
                                    type: 'text-area',
                                    label: 'Description',
                                    placeholder: 'Enter description of project'
                                },
                                {
                                    name: 'url',
                                    label: 'URL',
                                    placeholder: 'Enter live url'
                                },
                            ]}
                        />
                        <ListField
                            formik={formik}
                            name={'achievements'}
                            label={'Awards and Achievements'}
                            fields={[
                                {
                                    name: 'title',
                                    label: 'Title',
                                    placeholder: 'Enter title'
                                },
                                {
                                    name: 'description',
                                    type: 'text-area',
                                    label: 'Description',
                                    placeholder: 'Enter description'
                                },
                                {
                                    name: 'url',
                                    label: 'URL',
                                    placeholder: 'Enter url of certificate'
                                },
                            ]}
                        />
                        <div className="mb-6">
                            <button type="submit" className="w-full bg-blue-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline hover:bg-blue-600">Save</button>
                        </div>
                    </form>
                </div>
                <div className='w-1/2 h-screen overflow-auto hidden0 print:w-full print:block print:overflow-visible'>
                    <div className='border-2 border-black m-10 print:border-0 print:m-0 h-screen'>
                        <div className='ubuntu-mono border-0 border-dashed border-black text-justify px-5'>
                            <h1 className='text-2xl text-center'>{formik.values.name}</h1>
                            <div>
                                {
                                    formik.values.phone && <div><b>Phone:</b> <a className='text-blue-700 hover:text-blue-900' target='_blank' href={`tel:+91${formik.values.phone.replaceAll(' ', '')}`}>{formik.values.phone}</a></div>
                                }
                                {
                                    formik.values.email && <div><b>Email:</b> <a className='text-blue-700 hover:text-blue-900' target='_blank' href={`mailto:${formik.values.email}`}>{formik.values.email}</a></div>
                                }
                                {
                                    formik.values.linkedIn && <div><b>LinkedIn:</b> <a className='text-blue-700 hover:text-blue-900' target='_blank' href={`https://${formik.values.linkedIn}`}>{formik.values.linkedIn}</a></div>
                                }
                                {
                                    formik.values.github && <div><b>Github:</b> <a className='text-blue-700 hover:text-blue-900' target='_blank' href={`https://${formik.values.github}`}>{formik.values.github}</a></div>
                                }
                            </div>
                            {
                                formik.values.summary && <section className='page-break'>
                                    <br />
                                    <div className='border-t-4 border-double border-gray-500'></div>
                                    <h2 className='text-xl font-bold'>Professional Summary</h2>
                                    <div className='my-2'>
                                        <ul>
                                            <li className='mt-2'>
                                                &emsp;&emsp;{formik.values.summary}
                                            </li>
                                        </ul>
                                    </div>
                                </section>
                            }
                            {
                                formik.values.education.length != 0 && <section className='page-break'>
                                    <br />
                                    <div className='border-t-4 border-double border-gray-500'></div>
                                    <h2 className='text-xl font-bold'>Education</h2>
                                    <div className='my-2'>
                                        <ul className='list-[square] mx-8'>
                                            {
                                                formik.values.education.map((education, index) => (
                                                    <li key={index} className='mt-2'>
                                                        <b>
                                                            {
                                                                education.degree && education.degree || <span className='text-red-500'>Degree is missing</span>
                                                            }
                                                        </b>
                                                        <ul className='list-[circle] ml-8'>
                                                            <li>
                                                                {
                                                                    education.organization || <span className='text-red-500'>Organization is missing</span>
                                                                }
                                                            </li>
                                                            <li>
                                                                {
                                                                    education.duration || <span className='text-red-500'>Duration is missing</span>
                                                                }
                                                            </li>
                                                            {
                                                                education.result && <li>{education.result}</li>
                                                            }
                                                        </ul>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </section>
                            }
                            {
                                formik.values.skills.length != 0 && <section className='page-break'>
                                    <br />
                                    <div className='border-t-4 border-double border-gray-500'></div>
                                    <h2 className='text-xl font-bold'>Technical Skills</h2>
                                    <div className='my-2'>
                                        <ul className='list-[square] mx-8'>
                                            {
                                                formik.values.skills.filter(skill => skill.replaceAll(' ', '').length).map((skill, index) => (
                                                    <li key={index}>{skill}</li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </section>
                            }
                            {
                                formik.values.experience.length != 0 && <section className='page-break'>
                                    <br />
                                    <div className='border-t-4 border-double border-gray-500'></div>
                                    <h2 className='text-xl font-bold'>Experience</h2>
                                    <div className='my-2'>
                                        <ul className='list-[square] mx-8'>
                                            {
                                                formik.values.experience.map((experience, index) => (
                                                    <li key={index} className='mt-2'>
                                                        <b>
                                                            {
                                                                experience.organization && experience.organization || <span className='text-red-500'>Organization is missing</span>
                                                            }
                                                        </b>
                                                        <ul className='list-[circle] ml-8'>
                                                            {
                                                                experience.role && <li>{experience.role}</li>
                                                            }
                                                            {
                                                                experience.duration && <li>{experience.duration}</li>
                                                            }
                                                            {
                                                                experience.details && <li>{experience.details}</li>
                                                            }
                                                        </ul>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </section>
                            }
                            {
                                formik.values.projects.length != 0 && <section className='page-break'>
                                    <br />
                                    <div className='border-t-4 border-double border-gray-500'></div>
                                    <h2 className='text-xl font-bold'>Projects</h2>
                                    <div className='my-2'>
                                        <ul className='list-[square] mx-8'>
                                            {
                                                formik.values.projects.map((projects, index) => (
                                                    <li key={index} className='mt-2'>
                                                        <b>
                                                            {
                                                                projects.title && projects.title || <span className='text-red-500'>Project title is missing</span>
                                                            }
                                                        </b>
                                                        <ul className='list-[circle] ml-8'>
                                                            {
                                                                projects.description && <li>{projects.description}</li>
                                                            }
                                                            {
                                                                projects.url && <li><a className='text-blue-700 hover:text-blue-900' target='_blank' href={`https://${projects.url}`}>{projects.url}</a></li>
                                                            }
                                                        </ul>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </section>
                            }
                            {
                                formik.values.achievements.length != 0 && <section className='page-break'>
                                    <br />
                                    <div className='border-t-4 border-double border-gray-500'></div>
                                    <h2 className='text-xl font-bold'>Achievements</h2>
                                    <div className='my-2'>
                                        <ul className='list-[square] mx-8'>
                                            {
                                                formik.values.achievements.map((achievements, index) => (
                                                    <li key={index} className='mt-2'>
                                                        <b>
                                                            {
                                                                achievements.title && achievements.title || <span className='text-red-500'>Award or Achievement title is missing</span>
                                                            }
                                                        </b>
                                                        <ul className='list-[circle] ml-8'>
                                                            {
                                                                achievements.description && <li>{achievements.description}</li>
                                                            }
                                                            {
                                                                achievements.url && <li><a className='text-blue-700 hover:text-blue-900' target='_blank' href={`https://${achievements.url}`}>{achievements.url}</a></li>
                                                            }
                                                        </ul>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </section>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Resume