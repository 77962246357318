import React from 'react'
import Resume from './Resume'
import Home from './Home'
import Navbar from './Navbar'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

const App = () => {
	return (
		<>
			<BrowserRouter>
				<Routes>
					<Route element={<Navbar><Home /></Navbar>} path='/' />
					<Route element={<Navbar><Resume /></Navbar>} path='/build' />
				</Routes>
			</BrowserRouter>
		</>
	)
}

export default App