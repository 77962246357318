import React from 'react'

const Home = () => {
    return (
        <>
            <div className="a4-page border-2 border-black m-auto my-10">
                <div className="ubuntu-mono border-0 border-dashed border-black text-justify px-5 my-10">
                    <h1 className="text-2xl text-center">Abhay M. Vachhani</h1>
                    <div>
                        <div>
                            <b>Phone:</b>{" "}
                            <a
                                className="text-blue-700 hover:text-blue-900"
                                target="_blank"
                                href="tel:+91xxxxxxxxxx"
                            >
                                xxxxx xxxxx
                            </a>
                        </div>
                        <div>
                            <b>Email:</b>{" "}
                            <a
                                className="text-blue-700 hover:text-blue-900"
                                target="_blank"
                                href="mailto:xxxxx@gmail.com"
                            >
                                xxxxx@gmail.com
                            </a>
                        </div>
                        <div>
                            <b>LinkedIn:</b>{" "}
                            <a
                                className="text-blue-700 hover:text-blue-900"
                                target="_blank"
                                href="https://linkedin.com/in/abhay-vachhani"
                            >
                                linkedin.com/in/abhay-vachhani
                            </a>
                        </div>
                        <div>
                            <b>Github:</b>{" "}
                            <a
                                className="text-blue-700 hover:text-blue-900"
                                target="_blank"
                                href="https://github.com/Abhay-Vachhani"
                            >
                                github.com/Abhay-Vachhani
                            </a>
                        </div>
                    </div>
                    <section className="page-break">
                        <br />
                        <div className="border-t-4 border-double border-gray-500" />
                        <h2 className="text-xl font-bold">Professional Summary</h2>
                        <div className="my-2">
                            <ul>
                                <li className="mt-2">
                                    &emsp;&emsp;As a passionate and dedicated individual, I am
                                    eager to kick-start my career in the field of software development.
                                    With a keen interest in Python programming, I have acquired a solid
                                    foundation in the language through self-study and hands-on projects.
                                    I possess strong problem-solving skills, attention to detail, and a
                                    drive for continuous learning. I am now seeking an opportunity to
                                    apply my Python knowledge and contribute to a dynamic organization
                                    where I can grow and expand my skills further.
                                </li>
                            </ul>
                        </div>
                    </section>
                    <section className="page-break">
                        <br />
                        <div className="border-t-4 border-double border-gray-500" />
                        <h2 className="text-xl font-bold">Education</h2>
                        <div className="my-2">
                            <ul className="list-[square] mx-8">
                                <li className="mt-2">
                                    <b>Master of Computer Applications (MCA)</b>
                                    <ul className="list-[circle] ml-8">
                                        <li>Atmiya University - Rajkot, Gujarat</li>
                                        <li>2022 - 2024</li>
                                        <li>CGPA: 9.03 / 10 (Third Semester)</li>
                                    </ul>
                                </li>
                                <li className="mt-2">
                                    <b>Bachelor of Computer Applications (BCA)</b>
                                    <ul className="list-[circle] ml-8">
                                        <li>Atmiya University - Rajkot, Gujarat</li>
                                        <li>2019-2022</li>
                                        <li>CGPA: 8.27 / 10</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </section>
                    <section className="page-break">
                        <br />
                        <div className="border-t-4 border-double border-gray-500" />
                        <h2 className="text-xl font-bold">Technical Skills</h2>
                        <div className="my-2">
                            <ul className="list-[square] mx-8">
                                <li>Programming Languages: Python, JavaScript, PHP, Java</li>
                                <li>Web Development: Django, React, Laravel</li>
                                <li>Automation Testing: Selenium</li>
                                <li>Mobile Development: Android</li>
                                <li>Database Management: MySQL</li>
                                <li>Version Control: Git</li>
                                <li>Software Tools: VSCode, PyCharm</li>
                                <li>
                                    Operating Systems: Linux (Ubuntu, Kali) Desktop and Server, Windows
                                </li>
                            </ul>
                        </div>
                    </section>
                    <section className="page-break">
                        <br />
                        <div className="border-t-4 border-double border-gray-500" />
                        <h2 className="text-xl font-bold">Experience</h2>
                        <div className="my-2">
                            <ul className="list-[square] mx-8">
                                <li className="mt-2">
                                    <b>Imbuesoft LLP - Rajkot</b>
                                    <ul className="list-[circle] ml-8">
                                        <li>Role: Python Developer</li>
                                        <li>18th December, 2023 - Present</li>
                                        <li>Type: Internship</li>
                                    </ul>
                                </li>
                                <li className="mt-2">
                                    <b>Freelancing</b>
                                    <ul className="list-[circle] ml-8">
                                        <li>
                                            Worked on an ERP-based product, specializing in developing
                                            solutions for accountants.
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </section>
                    <section className="page-break">
                        <br />
                        <div className="border-t-4 border-double border-gray-500" />
                        <h2 className="text-xl font-bold">Projects</h2>
                        <div className="my-2">
                            <ul className="list-[square] mx-8">
                                <li className="mt-2">
                                    <b>Code 4 Me</b>
                                    <ul className="list-[circle] ml-8">
                                        <li>
                                            Developed a website called Code 4 Me that allows users to read
                                            blogs, leave comments, share live text, and backup files. The
                                            website is built using the frameworks Bootstrap and Django.
                                        </li>
                                        <li>
                                            <a
                                                className="text-blue-700 hover:text-blue-900"
                                                target="_blank"
                                                href="https://code4me.pythonanywhere.com"
                                            >
                                                code4me.pythonanywhere.com
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="mt-2">
                                    <b>Automatic Doc Generator</b>
                                    <ul className="list-[circle] ml-8">
                                        <li>
                                            Created a Python script that automates the process of copying
                                            code from VSCode to Google Docs. The script captures a
                                            screenshot of the program's output and modifies it to include
                                            personal details. This automation significantly reduces manual
                                            effort by generating assignment files in various programming
                                            languages with ease.
                                        </li>
                                    </ul>
                                </li>
                                <li className="mt-2">
                                    <b>Attendance Update Notifier</b>
                                    <ul className="list-[circle] ml-8">
                                        <li>
                                            Developed an Attendance Update Notifier using Python and Discord
                                            API integration. Leveraging the Discord platform, this tool
                                            sends real-time notifications to a dedicated channel whenever
                                            there are updates in the CMS of Atmiya University. By providing
                                            subject names, lecture details, attendance percentages, and
                                            more, it streamlines attendance management and facilitates
                                            effective communication among students and faculty.
                                        </li>
                                    </ul>
                                </li>
                                <li className="mt-2">
                                    <b>Gyan GPT (Telegram Chatbot)</b>
                                    <ul className="list-[circle] ml-8">
                                        <li>
                                            Integrated a ChatGPT with the Telegram messaging platform. Named
                                            "Gyan GPT," the Chatbot utilizes natural language processing and
                                            AI to provide interactive and informative responses to user
                                            queries. The bot offers personalized assistance, answers
                                            questions, and engages in meaningful conversations on various
                                            topics. Its integration with Telegram enhances user
                                            accessibility and extends the capabilities of the GPT model in a
                                            user-friendly manner. Which is generated by the help of Chat
                                            GPT.
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </section>
                    <section className="page-break">
                        <br />
                        <div className="border-t-4 border-double border-gray-500" />
                        <h2 className="text-xl font-bold">Achievements</h2>
                        <div className="my-2">
                            <ul className="list-[square] mx-8">
                                <li className="mt-2">
                                    <b>Automatic Certificate Generator (Atmiya University Hackathon)</b>
                                    <ul className="list-[circle] ml-8">
                                        <li>
                                            Developed an Automatic Certificate Generator during the Atmiya
                                            University Hackathon. This project utilized a tech stack
                                            including React, Node.js, and MongoDB to streamline the creation
                                            and distribution of certificates for various events and
                                            educational programs.
                                        </li>
                                    </ul>
                                </li>
                                <li className="mt-2">
                                    <b>Python Charmer</b>
                                    <ul className="list-[circle] ml-8">
                                        <li>
                                            Achieved the first prize in the Python Charmer event at
                                            Cybershadez, a prestigious technical competition organized by
                                            GLS University, Ahmedabad. Demonstrated exceptional skills and
                                            proficiency in Python programming through various challenges and
                                            competitions.
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default Home